import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ContentModal = (props) => {
  const { modal, headerText, toggleModal, children, ...modalProps } = props;
  const toggle = () => toggleModal();

  const defaultModalProps = {
    zIndex: 1099,
  };

  return (
    <Modal
      isOpen={modal}
      centered
      toggle={toggle}
      {...defaultModalProps}
      {...modalProps}
    >
      {headerText && <ModalHeader toggle={toggle}>{headerText}</ModalHeader>}
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

ContentModal.defaultProps = {
  modal: false,
  headerText: '',
  toggleModal: () => {},
};

ContentModal.propTypes = {
  modal: PropTypes.bool,
  children: PropTypes.node.isRequired,
  toggleModal: PropTypes.func,
  headerText: PropTypes.string,
};

export default ContentModal;
